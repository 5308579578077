import React, { useEffect, Component } from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx';
import { graphql} from 'gatsby'
import ParallaxConfig from '../components/PlxConfig'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import Arrow from '../components/Arrow'
import Section from '../components/Section'
import sectionStyles from '../styles/sections.sass'
import projectStyles from '../styles/project.sass'
import heroStyles from '../styles/hero.sass';

function projectLink(link, classModifier) {
  if (!link) return null

  return  <a href={'/case/' + link.slug} className={"projectNav projectNav--" + classModifier}>
              <span className="projectNav-link-title">
                <Arrow orientation={classModifier == 'next' ? "down" : "up"} size="small"/>
                <span dangerouslySetInnerHTML={{ __html: link.title }}/>
              </span>
              <br/>
              <span className="projectNav-link-subtitle">{link.featured_media ? link.featured_media.title : null}</span>
          </a>
}

function getMediaOrNull(post) {
  if (post.acf.featured_media && post.acf.featured_media.localFile) {
    if (post.acf.featured_media.mime_type.indexOf('image') === -1) {
      return (
        <div className="hero">
          <Plx parallaxData={ [ParallaxConfig('translateY', 0, 400, 1000)] } className="hero-mediaWrapper">
            <video className="hero-media" autoPlay muted playsInline loop>
                <source src={ post.acf.featured_media.localFile.publicURL } type={ post.acf.featured_media.mime_type }/>
            </video>
          </Plx>
        </div>
      )
    } else {
      return <div className="hero" style={{backgroundPosition: 'center', backgroundImage: 'url(' + post.acf.featured_media.localFile.publicURL + ')'}}></div>
    }
  } else if (post.featured_media) {
    return <div className="hero" style={{backgroundPosition: 'center', backgroundImage: 'url(' + post.featured_media.localFile.childImageSharp.fluid.src + ')'}}></div>
  } else {
    return null
  }
}

function getImageOrNull(image) {
  if (!image) return null

  return <div className="hero" style={{backgroundPosition: 'center', backgroundImage: 'url(' + image.localFile.childImageSharp.fluid.src + ')'}}></div>
}

function getImageHeight(image) {
  return {height: image ? image.height : null}
}

const BlogPost = ({ data, pageContext }) => {
  useEffect(() => {
    window.scrollTo(0,0)
  })
  const { wordpressPost: post, allWordpressWpMedia: mediaResult } = data
  const { prev, next } = pageContext
  const previousLink = projectLink(prev, 'prev')
  const nextLink = projectLink(next, 'next')
  const nextName = ((next && next.acf && next.acf.tagline) ? next.acf.tagline : 'Next project').split(' ')
  const heroImage = getMediaOrNull(post)

  if (mediaResult !== null && post.acf.text !== null) {
    const images = mediaResult.edges
    const imageExtension = /\.[jpegn]{3,}/
    const findAllImagesIncludeSize = /(-\d{2,4}x\d{2,4})?(\.[jpegn]{3,})/g

    // TODO: Test performance of this. Otherwise images must be separate sections until the image plugin is ready for use
    for(var i = 0; i < images.length; i++){
      if (images[i].node.localFile) {
        var currentImageName = images[i].node.source_url.replace(imageExtension,'')
        var replaceText = images[i].node.localFile.childImageSharp.sizes.src.replace(imageExtension,'')
        var findAllCurrentImages = new RegExp(currentImageName, 'g')
        post.acf.text = post.acf.text.replace(findAllCurrentImages, replaceText)
        post.acf.text = post.acf.text.replace(findAllImagesIncludeSize, '$2')

        for (var j = 0; j < post.acf.sections.length; j++) {
          if (post.acf.sections[j].text !== null) {
            post.acf.sections[j].text = post.acf.sections[j].text.replace(findAllCurrentImages, replaceText)
            post.acf.sections[j].text = post.acf.sections[j].text.replace(findAllImagesIncludeSize, '$2')
          }
        }
      }
    }
  }

  return (
    <Layout title={post.title} metatitle={post.title} metadesc={post.yoast_meta.yoast_wpseo_metadesc} metaimage={post.featured_media ? post.featured_media.localFile.childImageSharp.fluid.src : (post.acf.featured_media ? post.acf.featured_media.localFile.publicURL : null)}>
      <Plx className="heroWrapper" parallaxData={ [ParallaxConfig('scale', 1, 0.9, 200)] }>
        <Plx className="plx heroImageWrapper" style={{height: '100%'}} parallaxData={ [ParallaxConfig('rotateX', 0, -2, 200, 'deg'),ParallaxConfig('rotateX', -2, 0, 200, 'deg', 10)/*ParallaxConfig('rotateY', 0, 0, 800, 'deg'), ParallaxConfig('rotateZ', 0, 0, 800, 'deg'), ParallaxConfig('rotate', 1, 2, 800, 'deg')*/] }>
        {heroImage}
        </Plx>
        {/* <Plx parallaxData={ [ParallaxConfig('top',100,0,800,'%'),ParallaxConfig('opacity',0,1,100)] } style={heroImageHeight} className="plx hero-title">
          <h1>{post.acf.tagline}</h1>
        </Plx> */}
      </Plx>
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="post-intro" dangerouslySetInnerHTML={{ __html: post.acf.text ? post.acf.text.replace(/style="font-weight: 400;"/g, '') : null }} />
            </div>
          </div>
        </div>
      </section>
      {post.acf.sections && post.acf.sections.map((section, index) => (
        <Section section={section} key={"section-" + index} />
      ))}
      {previousLink}
      {nextLink}
      {next &&
      <a href={"/case/" + next.slug} className="nextProject nextProject--alone">
        {getImageOrNull(next.featured_media)}
        <Plx parallaxData={ [ParallaxConfig('translateX', 0, 200, 400)] } className="plx sectionHeader sectionHeader--next">Next Project</Plx>
        <div className="nextProjectHeaderWrapper">
          <Plx parallaxData={ [ParallaxConfig('opacity', 0, 1, 200)] } className="plx nextProjectHeader">
            <div className="nextProjectHeader-title" dangerouslySetInnerHTML={{ __html: next.title }}/>
            <div className="nextProjectHeader-text">
              {nextName.map((word, index) => (
                <span key={index} className="nextProjectHeader-text-word">
                  {word}
                  {index === nextName.length - 1 &&
                    <Arrow orientation="right"/>
                  }
                </span>
              ))}
            </div>
          </Plx>
        </div>
      </a>
      }
      <Footer />
    </Layout>
  )
}

BlogPost.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!, $wordpress_id: Int!) {
    allWordpressWpMedia(
      filter : {
        post : {
          eq : $wordpress_id
        }
        mime_type : {
          in : ["image/jpeg", "image/png"]
        }
      }) {
      edges {
        node {
          post
          source_url
          localFile {
            extension
            childImageSharp {
              sizes {
                src
              }
            }
          }
        }
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      featured_media {
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 960) {
              src
              #srcWebp
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf{
        tagline
        text
        featured_media {
          mime_type
          localFile {
            publicURL
          }
        }
        sections {
          type
          header
          text
          media {
            mime_type
            localFile {
              publicURL
              childImageSharp{
                original{
                  height
                }
              }
            }
            alt_text
          }
        }
      }
    }
  }
`
