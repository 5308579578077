import React from 'react'
import { Link } from 'gatsby'
import Plx from 'react-plx';
import ParallaxConfig from '../components/PlxConfig'
import '../styles/sections.sass'
import InfoColumnSection from './InfoColumnSection';
import '../styles/infocolumnsection.sass'
import ReactDOM from "react-dom";


export default class Section extends React.Component {

    constructor(props) {
        super(props);

        this.state = { invertColorFocus: false, scrollStart: 0 };
    }

    componentDidMount() {
        if(this.props.section.type == 'backgroundFocused'){
            const node = ReactDOM.findDOMNode(this);
            window.addEventListener('scroll', () => {
                requestAnimationFrame(() => {
                    this.updateSectionState(node)
                })
            }, { passive: true });

            this.updateSectionState(node)
        }
    }

    getViewPortHeight = function(){
        var viewPortHeight = document.documentElement.clientHeight;
        const toggleDistance = 0.60 * viewPortHeight;
        return toggleDistance;
    }

    updateSectionState = (node) => {
        const boundingClientRect = node.getBoundingClientRect()
        const viewportHeight = this.getViewPortHeight()
        const focusedLength = 100
        const viewportToBoundingClientRatio = (viewportHeight) / 255

        if (boundingClientRect.top < viewportHeight + focusedLength && boundingClientRect.bottom > viewportHeight * -2) {
            if (!this.state.invertColorFocus) {
                this.setState({ invertColorFocus: true, scrollStart: boundingClientRect.top })
            }
            var newColorValue = 255 - (this.state.scrollStart - boundingClientRect.top) / viewportToBoundingClientRatio
            // Switch fading
             if (newColorValue < 200) {
                newColorValue = newColorValue * -1
            }

            node.style.backgroundColor= 'rgb(' + (newColorValue) + ',' + (newColorValue) + ', ' + (newColorValue) + ')';
            node.style.color= 'rgb(' + (255-newColorValue+50) + ',' + (255-newColorValue+50) + ', ' + (255-newColorValue+50) + ')';
        }
    }

    render() {

        const { section } = this.props

        return (
            <Plx className={"section section--" + section.type  + (this.state.invertColorFocus ? " section--invertedBackgroundColor": "")}>
                <div className={section.type !== 'fullwidth' ? "container content" : ''}>
                    {(section.header || section.text) &&
                    <div className={"subSection subSection--" + section.type}>
                        {section.header &&
                        ((section.type !== 'quote' && section.type !== 'mediaHeader' && section.type !== 'infoColumn' && section.type !== 'backgroundFocused') ?
                        <Plx parallaxData={ [ParallaxConfig('translateX', -400, 0, 400)] } className={"plx sectionHeader sectionHeader--" + section.type}>
                            <h2>{section.header}</h2>
                        </Plx>
                        :
                        ((section.type !== 'infoColumn' && section.type !== 'backgroundFocused' ? <h2>{section.header}</h2> : ""))
                        )
                        }

                        {section.header &&
                        (section.type === 'backgroundFocused' &&
                        <Plx parallaxData={ [ParallaxConfig('translateX', -400, 0, 400)]} className={"plx sectionHeader sectionHeader--" + section.type}>
                        <h2>{section.header}</h2>
                        </Plx>
                        )}

                        {section.text &&
                        (section.type !== 'mediaHeader' && section.type !== 'infoColumn' && section.type !== 'backgroundFocused' &&
                        <Plx style={{width: '100%'}} parallaxData={ [ParallaxConfig('translateY', 100, 0, 400)] } dangerouslySetInnerHTML={{ __html: section.text }} />
                        )}

                        {section.text &&
                        (section.type === 'backgroundFocused' && section.type !== 'mediaHeader' && section.type !== 'infoColumn' &&
                        <Plx style={{width: '100%'}} parallaxData={ [ParallaxConfig('translateY', 100, 0, 400)] }
                        dangerouslySetInnerHTML={{ __html: section.text }} />
                        )}

                        {section.text &&
                        (section.type === 'infoColumn' && section.type !== 'backgroundFocused'&&
                        <InfoColumnSection infocolumnsection={section}></InfoColumnSection>
                        )}

                        {section.text &&
                        (section.type === 'mediaHeader' && section.type !== 'backgroundFocused'&&
                        <div dangerouslySetInnerHTML={{ __html: section.text }} />
                        )}

                    </div>
                    }
                    {section.media && section.media.localFile && section.media.mime_type.indexOf('image') !== -1 && section.type !== 'infoColumn' &&
                    <div className={"subSection subSection--" + section.type}>
                        <div className="subSection-media">
                            {/* <Plx parallaxData={ [ParallaxConfig('translateY', -100, 0, 1000)] }> */}
                            <img className="subSection-media-image" src={ section.media.localFile.publicURL } />

                        </div>
                    </div>
                    }
                    {section.media && section.media.localFile && section.media.mime_type.indexOf('image') === -1 && section.type !== 'infoColumn' &&
                    <div className={"subSection subSection--" + section.type}>
                        <div className="subSection-media">
                            {/* <Plx parallaxData={ [ParallaxConfig('translateY', -100, 0, 1000)] }> */}
                            <video className="subSection-media-video" autoPlay muted playsInline loop>
                                <source src={ section.media.localFile.publicURL } type={ section.media.mime_type }/>
                            </video>
                            {/* </Plx> */}
                        </div>
                    </div>
                    }
                </div>
            </Plx>
        )
    }
}
