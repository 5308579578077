import React from 'react'
import { Link } from 'gatsby'
import Plx from 'react-plx';
import ParallaxConfig from '../components/PlxConfig'
import '../styles/infocolumnsection.sass'
import '../styles/sections.sass'

export default class InfoColumnSection extends React.Component {
  render() {
    const { infocolumnsection } = this.props 

    return (
      <div className="info-column-wrapper">
      <div className="sectionrow">
           <div className="column">
            <div className="card ">
                <div className=""><img src={ infocolumnsection.media.localFile.publicURL } /></div>
                    <div className="cardColumn">
                        <div className="cardText">
                        <div className="contacts-list-item-header"><h3>{infocolumnsection.header}</h3></div>
                        <div className="highlight">{infocolumnsection.profession}</div>
                          <div className="cardContact">
                            <div>{infocolumnsection.email}</div>
                            <div>{infocolumnsection.phone}</div>
                          </div>
                        </div>
                    </div>
                </div>
              <div  className="infoText" dangerouslySetInnerHTML={{ __html: infocolumnsection.text }}></div>
            </div>    
          {this.renderSection(infocolumnsection)}
        </div>
      </div>
    )
  }

  renderSection(infocolumnsection){
    let infosection = []
    if(infocolumnsection.infogroup.length > 0){
      let remove_null = infocolumnsection.infogroup.filter(function (el){
        return el != null
      })
      let infosection = remove_null.map((item, i) => (
          <div className="column" key={"a" + i}>
          <div className="card" key={"b" + i}> 
          <div><img src={ item.media.localFile.publicURL } key={"c" + i} /></div>
              <div className="cardColumn" key={"d" + i}>
                  <div className="cardText" key={"e" + i}>
                  <div className="contacts-list-item-header" key={"f" + i}><h3>{item.header}</h3></div>
                  <div className="highlight" key={"g"+ i}>{item.profession}</div>
                    <div className="cardContact" key={"h" + i}>
                      <div>{item.email}</div>
                      <div>{item.phone}</div>
                    </div>
                  </div>
              </div>
          </div>
          <div  className="infoText" key={"i" + i} dangerouslySetInnerHTML={{ __html: item.text }} />
          </div>
       ))

       let add_empty_card = (infocolumnsection.infogroup.length) % 2 === 0
       if(add_empty_card ){
        infosection.push(<div className="column" key="j"></div>)
       }
       return(infosection)
      }
    }
}
